import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { ThemeState } from '@portal/features/design-core/store/theme/theme.state';
import { combineLatest, map, Observable } from 'rxjs';
import { AsyncPipe, NgIf } from '@angular/common';
import { CompanyInfoState } from '@portal/store/company-info/company-info.state';
import { first } from 'lodash-es';
import { ImageSkeletonDirective } from '@hesti/directives/image-skeleton/image-skeleton.directive';

@Component({
  standalone: true,
  selector: 'hp-logo',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [AsyncPipe, NgIf, ImageSkeletonDirective],
})
export class LogoComponent implements OnInit {
  @Input() public height: number = 27;

  public readonly isDarkTheme$: Observable<boolean> = this.store.select(ThemeState.isDarkTheme);

  public src$: Observable<string | undefined>;

  public get companyName(): string | undefined {
    return this.store.selectSnapshot(CompanyInfoState.companyGeneralInfo)?.name;
  }

  public constructor(private readonly store: Store) {}

  public ngOnInit(): void {
    this.src$ = combineLatest([this.isDarkTheme$, this.store.select(CompanyInfoState.companyGeneralInfo)]).pipe(
      map(([isDarkTheme, companyGeneralInfo]) => {
        const themeLogoUrl = companyGeneralInfo?.logos.find((x) => x.themeType === (isDarkTheme ? 'Dark' : 'Light'))?.url;
        const firstLogoUrl = first(companyGeneralInfo?.logos)?.url;
        return themeLogoUrl || firstLogoUrl;
      }),
    );
  }
}
