import { Theme } from '@hesti/models/theme/theme.model';

export namespace ThemeStateActions {
  const prefix = '[Theme]';

  export class LoadUserTheme {
    public static readonly type = `${prefix} LoadUserTheme`;
  }

  export class SetUserTheme {
    public static readonly type = `${prefix} SetUserTheme`;

    public constructor(public readonly userTheme: Theme | undefined) {}
  }
}
