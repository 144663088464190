import { AbstractControl, FormArray, ValidatorFn } from '@angular/forms';
import { ValidationError } from '@hesti/models/validation-error.model';
import { CommonTranslationKey } from '@hesti/models/translation-key.model';

export class UniquenessInArrayValidator {
  public static uniquenessInArray =
    (messageKey: CommonTranslationKey): ValidatorFn =>
    (formArray: AbstractControl): ValidationError | null => {
      if (!(formArray instanceof FormArray)) {
        return null;
      }

      const values = formArray.controls.map((control) => control.value);

      const duplicates = this.findDuplicates(values);

      formArray.controls.forEach((control) => {
        if (duplicates.includes(control.value) && !control.pristine) {
          control.setErrors(new ValidationError(messageKey));
        } else if (control.errors?.['messageKey'] === messageKey) {
          control.setErrors(null);
        }
      });

      return null;
    };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private static findDuplicates(array: any[]): any[] {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const seen: any = {};
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const duplicates: any[] = [];

    for (const item of array) {
      if (seen[item]) {
        duplicates.push(item);
      } else {
        seen[item] = true;
      }
    }

    return duplicates.filter((value, index, self) => self.indexOf(value) === index);
  }
}
