import { AbstractControl, ValidatorFn, Validators } from '@angular/forms';
import { ValidationError } from '@hesti/models/validation-error.model';
import { CommonTranslationKey } from '@hesti/models/translation-key.model';
import { isUndefined } from 'lodash-es';

export class MinValidator {
  public static min =
    (min: number, isStrict: boolean = false, messageKey: CommonTranslationKey | null | undefined = undefined): ValidatorFn =>
    (control: AbstractControl): ValidationError | null => {
      if (isUndefined(messageKey)) {
        messageKey = isStrict ? 'api.validationError.minStrict' : 'api.validationError.min';
      }
      const validationError = new ValidationError(messageKey, { min });
      return Validators.min(min)(control) ? validationError : null;
    };
}
