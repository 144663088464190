import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Theme } from '@hesti/models/theme/theme.model';

@Injectable({ providedIn: 'root' })
export class ThemeService {
  private currentThemeClass: string;

  private get documentElement(): Element {
    return this.document.documentElement;
  }

  public constructor(@Inject(DOCUMENT) private readonly document: Document) {}

  public applyTheme(theme: Theme): void {
    this.removePreviousTheme();

    this.currentThemeClass = theme.toLowerCase();
    this.documentElement.classList.add(this.currentThemeClass);
  }

  private removePreviousTheme(): void {
    if (this.currentThemeClass) {
      this.documentElement.classList.remove(this.currentThemeClass);
    }
  }
}
