import { Action, NgxsOnInit, Selector, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { LanguageStateModel } from '@hesti/store/language/language.state.model';
import { LanguageStateActions } from '@hesti/store/language/language.state.actions';
import { TranslateService } from '@ngx-translate/core';
import { StoreConst } from '@hesti/constants/store.const';
import { LocalStorageService } from '@hesti/services/local-storage/local-storage.service';
import { IdentityApiService } from '@hesti/services/api/identity-api.service';
import { finalize, map, Observable } from 'rxjs';
import { SpinnerService } from '@hesti/services/spinner/spinner.service';
import { AdminLanguage } from '@hesti/constants/enums/language/admin-language.enum';
import { LanguageUtils } from '@hesti/utils/language.utils';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { environment } from '../../../../environments/environment';

@UntilDestroy()
@State<LanguageStateModel>({
  name: StoreConst.LanguageState,
  defaults: {
    language: LanguageUtils.parseAdminLanguage(environment.defaultAdminLanguage),
  },
})
@Injectable()
export class LanguageState implements NgxsOnInit {
  public constructor(
    private readonly localStorageService: LocalStorageService,
    private readonly translateService: TranslateService,
    private readonly identityApiService: IdentityApiService,
    private readonly spinnerService: SpinnerService,
  ) {}

  @Selector()
  public static language({ language }: LanguageStateModel): AdminLanguage {
    return language;
  }

  @Action(LanguageStateActions.SetLanguage)
  public setLanguage(context: StateContext<LanguageStateModel>, { language }: LanguageStateActions.SetLanguage): void {
    this.changeLanguage(context, language);
  }

  @Action(LanguageStateActions.SetAuthenticatedUserLanguage)
  public setAuthenticatedUserLanguage(
    context: StateContext<LanguageStateModel>,
    { language }: LanguageStateActions.SetAuthenticatedUserLanguage,
  ): void {
    this.identityApiService
      .setUserInterfaceLanguage(language)
      .pipe(untilDestroyed(this))
      .subscribe(() => this.changeLanguage(context, language));
  }

  @Action(LanguageStateActions.LoadAuthenticatedUserLanguage)
  public loadAuthenticatedUserLanguage(context: StateContext<LanguageStateModel>): Observable<void> {
    this.spinnerService.show();

    return this.identityApiService.getUserInterfaceLanguage().pipe(
      map((language) => this.changeLanguage(context, language || context.getState().language)),
      finalize(() => this.spinnerService.hide()),
    );
  }

  @Action(LanguageStateActions.RestoreLanguage)
  public restoreLanguage(context: StateContext<LanguageStateModel>): void {
    const language =
      this.localStorageService.get<AdminLanguage>(StoreConst.LanguageStateAdminLanguage) ||
      (this.translateService.defaultLang as AdminLanguage);
    this.changeLanguage(context, language);
  }

  public ngxsOnInit({ dispatch }: StateContext<LanguageStateModel>): void {
    dispatch(new LanguageStateActions.RestoreLanguage());
  }

  private changeLanguage({ patchState }: StateContext<LanguageStateModel>, language: AdminLanguage): void {
    this.translateService.use(language);
    this.localStorageService.set(StoreConst.LanguageStateAdminLanguage, language);
    patchState({ language });
  }
}
