import { Action, NgxsOnInit, Selector, State, StateContext } from '@ngxs/store';
import { StoreConst } from '@portal/constants/store.const';
import { CompanyApiService } from '@portal/services/api/company-api.service';
import { ApiErrorModel } from '@hesti/models/api-error/api-error.model';
import { ErrorCode } from '@hesti/constants/error-code.const';
import { CompanyGeneralInfoDtoModel } from '@hesti/models/company/company-general-info/company-general-info.dto-model';
import { Injectable } from '@angular/core';
import { CompanyInfoStateActions } from '@portal/store/company-info/company-info.state.actions';
import { catchError, Observable, of, tap } from 'rxjs';
import { LanguageStateActions } from '../language/language.state.actions';
import { CompanyInfoStateModel } from './company-info.model';

@State<CompanyInfoStateModel>({
  name: StoreConst.CompanyInfo,
  defaults: {
    subDomain: undefined,
    companyGeneralInfo: undefined,
    apiError: undefined,
  },
})
@Injectable()
export class CompanyInfoState implements NgxsOnInit {
  public constructor(private readonly companyInfoApiService: CompanyApiService) {}

  @Selector()
  public static companyGeneralInfo({ companyGeneralInfo }: CompanyInfoStateModel): CompanyGeneralInfoDtoModel | undefined {
    return companyGeneralInfo;
  }

  @Selector()
  public static apiError({ apiError }: CompanyInfoStateModel): ApiErrorModel | undefined {
    return apiError;
  }

  private get subDomain(): string {
    return window.location.host.split('.')[0];
  }

  @Action(CompanyInfoStateActions.LoadCompanyInfo)
  public loadCompanyInfo(context: StateContext<CompanyInfoStateModel>): Observable<unknown> {
    return this.companyInfoApiService.getCompanyGeneralInfoByCompanySubDomain(this.subDomain).pipe(
      tap((companyGeneralInfo) => this.setCompanyInfo(context, companyGeneralInfo)),
      catchError(() => {
        this.setError(context);
        return of();
      }),
    );
  }

  @Action(CompanyInfoStateActions.UpdateCompanyLogos)
  public updateCompanyLogos(
    { patchState, getState }: StateContext<CompanyInfoStateModel>,
    { logos }: CompanyInfoStateActions.UpdateCompanyLogos,
  ): void {
    const { companyGeneralInfo } = getState();
    if (!companyGeneralInfo) {
      return;
    }
    const updatedCompanyGeneralInfo = { ...companyGeneralInfo, logos };
    patchState({ companyGeneralInfo: updatedCompanyGeneralInfo });
  }

  @Action(CompanyInfoStateActions.UpdateThemeMode)
  public updateThemeMode(
    { patchState, getState }: StateContext<CompanyInfoStateModel>,
    { themeMode }: CompanyInfoStateActions.UpdateThemeMode,
  ): void {
    const { companyGeneralInfo } = getState();
    if (!companyGeneralInfo) {
      return;
    }
    const updatedCompanyGeneralInfo = { ...companyGeneralInfo, themeMode };
    patchState({ companyGeneralInfo: updatedCompanyGeneralInfo });
  }

  public ngxsOnInit({ dispatch }: StateContext<CompanyInfoStateModel>): void {
    dispatch(new CompanyInfoStateActions.LoadCompanyInfo());
  }

  private setCompanyInfo(
    { patchState, dispatch }: StateContext<CompanyInfoStateModel>,
    companyGeneralInfo: CompanyGeneralInfoDtoModel,
  ): void {
    dispatch(new LanguageStateActions.SetLanguages(companyGeneralInfo.languages, companyGeneralInfo.defaultLanguage));

    patchState({
      companyGeneralInfo,
      subDomain: this.subDomain,
      apiError: undefined,
    });
  }

  private setError({ patchState }: StateContext<CompanyInfoStateModel>): void {
    patchState({
      subDomain: undefined,
      companyGeneralInfo: undefined,
      apiError: new ApiErrorModel({ Code: ErrorCode.InternalServerError, MessageKey: 'api.commonError.internalServerError' }),
    });
  }
}
