import { ImageModel } from '@hesti/models/image/image.model';
import moment from 'moment';
import { ImageGroupModel } from '@hesti/models/image-group/image-group.model';

export class ImageConst {
  public static readonly PropertyPlaceholder: ImageModel = {
    id: '',
    createdAt: moment(),
    src: './shared-assets/img/image-placeholder.png',
    order: 0,
  };
  public static readonly PropertyGroupPlaceholder: ImageGroupModel = {
    id: '',
    createdAt: moment(),
    order: 0,
    images: [ImageConst.PropertyPlaceholder],
  };
  public static readonly UserPlaceholder = './shared-assets/img/avatar.png';
}
