import { environment } from '../../../../../environments/environment';

const buildApiUrl = (path: string): string => `${environment.apiUrl}/${path}`;

export const ManagePortalPath = 'manage-portal';

export const ApiConst = {
  Company: {
    GetCompanyGeneralInfoByCompanySubDomain: (subDomain: string): string => buildApiUrl(`company/generalInfo/${subDomain}`),
    UpdateLogos: buildApiUrl(`company/${ManagePortalPath}/logo`),
  },
  Property: {
    GetCompanyProperty: (companyId: string, id: string): string => buildApiUrl(`property/${id}/company/${companyId}`),
    GetCompanyProperties: (companyId: string): string => buildApiUrl(`property/company/${companyId}/list`),
    GetCompanyPropertiesMapItems: (companyId: string): string => buildApiUrl(`property/company/${companyId}/mapItems`),
  },
  Theme: {
    GetCompanyAppearance: (companyId: string): string => buildApiUrl(`theme/company/${companyId}/appearance.css`),
    UpdateCompanyAppearance: buildApiUrl(`theme/${ManagePortalPath}/appearance`),
    GetSelectedThemes: buildApiUrl(`theme/${ManagePortalPath}/selected-themes`),
    GetAvailableThemes: buildApiUrl(`theme/${ManagePortalPath}/available-themes`),
    GetPreviewAppearance: buildApiUrl(`theme/${ManagePortalPath}/preview-appearance`),
    DeleteTheme: (themeId: string): string => buildApiUrl(`theme/${ManagePortalPath}/theme/${themeId}`),
  },
};
