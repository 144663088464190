import { AbstractControl, ValidatorFn } from '@angular/forms';
import { CommonTranslationKey } from '@hesti/models/translation-key.model';
import { ValidationError } from '@hesti/models/validation-error.model';
import moment from 'moment';

export class DateRangeValidators {
  public static compareDate =
    <TModel>(
      fromControlName: keyof TModel,
      toControlName: keyof TModel,
      messageKey: CommonTranslationKey | null = 'api.validationError.dateThan',
    ): ValidatorFn =>
    (formGroup: AbstractControl): ValidationError | null => {
      const fromControl = formGroup.get(fromControlName as string);
      const toControl = formGroup.get(toControlName as string);
      const validationError = new ValidationError(messageKey);
      const error = moment(fromControl!.value, 'DD/MM/YYYY') > moment(toControl!.value, 'DD/MM/YYYY') ? validationError : null;
      toControl!.updateValueAndValidity({ onlySelf: true });
      toControl!.setErrors(error);
      return error;
    };
}
