<img
  *ngIf="src$ | async as src; else companyNameContainer"
  alt="logo"
  class="logo"
  haImageSkeleton
  [style]="{ height: height + 'px' }"
  [src]="src"
/>
<ng-template #companyNameContainer>
  <span class="company-name">{{ companyName }}</span>
</ng-template>
