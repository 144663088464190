import { Theme } from '@hesti/models/theme/theme.model';

export namespace ThemeStateActions {
  const prefix = '[Theme]';

  export class ToggleTheme {
    public static readonly type: string = `${prefix} ToggleTheme`;
  }

  export class SetForcedTheme {
    public static readonly type: string = `${prefix} SetForcedTheme`;

    public constructor(public readonly forcedTheme: Theme | undefined) {}
  }
}
