import { AbstractControl, ValidatorFn } from '@angular/forms';
import { CommonTranslationKey } from '@hesti/models/translation-key.model';
import { ValidationError } from '../../models/validation-error.model';

export class RepeatPasswordValidator {
  public static repeatPassword =
    <TModel>(
      passwordControlName: keyof TModel,
      confirmPasswordControlName: keyof TModel,
      messageKey: CommonTranslationKey | null = 'api.validationError.passwordConfirmationDoesntMatch',
    ): ValidatorFn =>
    (formGroup: AbstractControl): ValidationError | null => {
      const passwordControl = formGroup.get(passwordControlName as string);
      const confirmPasswordControl = formGroup.get(confirmPasswordControlName as string);
      const validationError = new ValidationError(messageKey);
      confirmPasswordControl!.updateValueAndValidity({ onlySelf: true });
      confirmPasswordControl!.setErrors(passwordControl?.value !== confirmPasswordControl!.value ? validationError : null);
      return null;
    };
}
