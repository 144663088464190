import { ApiErrorDtoModel } from '@hesti/models/api-error/dto/api-error.dto-model';
import { ErrorCode } from '@hesti/constants/error-code.const';
import { CommonTranslationKey } from '@hesti/models/translation-key.model';

export class ApiErrorModel {
  public get code(): ErrorCode {
    return this.dto.Code;
  }

  public get entityKey(): string {
    return this.dto.EntityKey || '';
  }

  public get messageKey(): CommonTranslationKey {
    return (this.dto.MessageKey || '') as CommonTranslationKey;
  }

  public constructor(private readonly dto: ApiErrorDtoModel) {}
}
