import moment, { Moment } from 'moment';

export class DateMapper {
  public static toModel(date?: string): Moment | undefined {
    if (!date) {
      return undefined;
    }

    return moment(date);
  }

  public static yearToModel(year: number | undefined): Moment | undefined {
    if (!year) {
      return undefined;
    }

    return moment(`${year}-01-01`);
  }

  public static toDto(date?: Moment): string | undefined {
    if (!date) {
      return undefined;
    }

    return date.utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
  }

  public static yearToDto(date?: Moment): number | undefined {
    if (!date) {
      return undefined;
    }

    return date.year();
  }
}
