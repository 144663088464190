import { NgxSpinnerConfig } from 'ngx-spinner/lib/config';

export class SpinnerConst {
  public static readonly NgxSpinnerConfig: NgxSpinnerConfig = { type: 'ball-clip-rotate' };
  public static readonly Primary: string = 'primary';
  public static readonly Account: string = 'account';
  public static readonly AccountPersonalInfo: string = 'account-personal-info';
  public static readonly AccountSecurity: string = 'account-security';
  public static readonly AccountRole: string = 'account-role';
  public static readonly AccountSettings: string = 'account-settings';
  public static readonly Company: string = 'company';
  public static readonly EditCompany: string = 'edit-company';
  public static readonly InviteCompanyMemberDialog: string = 'invite-company-member-dialog';
  public static readonly Property: string = 'property';
  public static readonly Description: string = 'description';
  public static readonly SubDomain: string = 'sub-domain';
}
