import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CommonTranslationKey } from '@hesti/models/translation-key.model';
import { PortalTranslatePipe } from '@portal/pipes/translate/portal-translate.pipe';

@Component({
  standalone: true,
  selector: 'hp-something-went-wrong',
  templateUrl: './something-went-wrong.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [PortalTranslatePipe],
})
export class SomethingWentWrongComponent {
  public readonly messageKey: CommonTranslationKey = 'api.commonError.somethingWentWrong';
}
