import { AbstractControl, ValidatorFn, Validators } from '@angular/forms';
import { ValidationError } from '@hesti/models/validation-error.model';
import { CommonTranslationKey } from '@hesti/models/translation-key.model';

export class MaxValidator {
  public static max =
    (max: number, messageKey: CommonTranslationKey | null = 'api.validationError.max'): ValidatorFn =>
    (control: AbstractControl): ValidationError | null => {
      const validationError = new ValidationError(messageKey, { max });
      return Validators.max(max)(control) ? validationError : null;
    };
}
