import { PortalLanguage } from '@hesti/constants/enums/language/portal-language.enum';

export namespace CompanyInfoStateActions {
  const prefix = '[CompanyInfo]';

  export class LoadLanguages {
    public static readonly type: string = `${prefix} LoadLanguages`;
  }

  export class SetLanguages {
    public static readonly type: string = `${prefix} SetLanguages`;

    public constructor(public readonly languages: PortalLanguage[] | undefined) {}
  }

  export class LoadSubdomain {
    public static readonly type: string = `${prefix} LoadSubdomain`;
  }
}
