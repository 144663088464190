import { Inject, Injectable } from '@angular/core';
import { APP_NAME } from '@hesti/injection-tokens/app-name.injection-token';
import { UserState } from '@hesti/store/user/user.state';
import { Store } from '@ngxs/store';

@Injectable({ providedIn: 'root' })
export class LocalStorageService {
  private readonly guest = 'guest';

  private get userId(): string {
    return this.store.selectSnapshot(UserState.jwtInfo)?.userInfo.Id || this.guest;
  }

  public constructor(
    @Inject(APP_NAME) private readonly appName: string,
    private readonly store: Store,
  ) {}

  public set(key: string, value: unknown, guestForce: boolean = false): void {
    const storedKey = !guestForce ? this.normalizeKey(key) : this.normalizeKey(key, this.guest);
    localStorage.setItem(storedKey, JSON.stringify(value));
  }

  public get<T>(key: string): T | null {
    const storedKey = this.normalizeKey(key);
    const guestKey = this.normalizeKey(key, this.guest);
    const valueJson = localStorage.getItem(storedKey) || localStorage.getItem(guestKey);
    return valueJson ? JSON.parse(valueJson) : null;
  }

  public remove(key: string): void {
    localStorage.removeItem(this.normalizeKey(key, this.guest));
    localStorage.removeItem(this.normalizeKey(key));
  }

  public clear(): void {
    localStorage.clear();
  }

  private normalizeKey = (localKey: string, username: string = this.userId): string => `${this.appName}.${username}.${localKey}`;
}
