import { Injectable } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Subject } from 'rxjs';
import { SpinnerConst } from '@hesti/constants/spinner.const';
import { SpinnerStack } from '@hesti/services/spinner/spinner-stack.model';

@UntilDestroy()
@Injectable({ providedIn: 'root' })
export class SpinnerService {
  public stacks: SpinnerStack[] = [];
  public readonly stackChanged$ = new Subject<SpinnerStack>();

  public constructor(private readonly spinnerService: NgxSpinnerService) {
    this.stackChanged$.pipe(untilDestroyed(this)).subscribe((stack) => {
      if (stack.shouldShowSpinner) {
        this.spinnerService.show(stack.name);
      }
      if (stack.shouldHideSpinner) {
        this.spinnerService.hide(stack.name);
      }
    });
  }

  public show(nameToShow = SpinnerConst.Primary): void {
    if (!this.stacks.map(({ name }) => name).includes(nameToShow)) {
      this.stacks.push(new SpinnerStack(nameToShow));
    }

    const stack = this.stacks.find((stackToAdd) => stackToAdd.name === nameToShow)!;
    stack.push();
    this.stackChanged$.next(stack);
  }

  public hide(nameToHide = SpinnerConst.Primary): void {
    if (!this.stacks.map(({ name }) => name).includes(nameToHide)) {
      return;
    }

    const stack = this.stacks.find((stackToHide) => stackToHide.name === nameToHide)!;
    stack.pop();
    this.stackChanged$.next(stack);
  }
}
