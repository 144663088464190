import { environment } from 'environments/environment';

const buildApiUrl = (path: string): string => `${environment.apiUrl}/${path}`;
const buildGeocodingApiUrl = (path: string): string => `${environment.geocodingApiUrl}/${path}`;

export const ApiConst = {
  Identity: {
    Register: buildApiUrl('identity/register'),
    SignIn: buildApiUrl('identity/signIn'),
    SignOut: buildApiUrl('identity/signOut'),
    RefreshToken: buildApiUrl('identity/refresh'),
    CheckEmailUniqueness: (email: string): string => buildApiUrl(`identity/checkEmailUniqueness/${email}`),
    ConfirmEmail: (token: string): string => buildApiUrl(`identity/confirmEmail/${token}`),
    ResetPassword: buildApiUrl('identity/resetPassword'),
    SignInWithGoogle: buildApiUrl('identity/signInWithGoogle'),
    GetUserInterfaceLanguage: buildApiUrl('identity/getUserInterfaceLanguage'),
    SetUserInterfaceLanguage: buildApiUrl('identity/setUserInterfaceLanguage'),
    CheckForPassword: buildApiUrl('identity/checkForPassword'),
    UpdatePassword: buildApiUrl('identity/updatePassword'),
    GetUserInfo: (id: string): string => buildApiUrl(`identity/userInfo/${id}`),
    UpdateMyUserInfo: buildApiUrl(`identity/myUserInfo`),
    UpdateUserInfo: (id: string): string => buildApiUrl(`identity/userInfo?id=${id}`),
    GetCompanyMembers: buildApiUrl(`identity/company-members`),
    GetCompanyMemberPreviews: (id: string): string => buildApiUrl(`identity/company-member-previews/${id}`),
    RegisterUsers: buildApiUrl('identity/registerUsers'),
    RegisterByInvitation: buildApiUrl('identity/registerByInvitation'),
    GetChangeEmailRequestLockoutEnd: (id: string): string => buildApiUrl(`identity/changeEmailRequestLockoutEnd?id=${id}`),
    ChangeEmail: (id: string): string => buildApiUrl(`identity/changeEmail?id=${id}`),
    GetUserRole: (id: string): string => buildApiUrl(`identity/userRole?id=${id}`),
    UpdateUserRole: (id: string): string => buildApiUrl(`identity/userRole?id=${id}`),
    GetUserRolesPermissions: (id: string): string => buildApiUrl(`identity/userRolesPermissions?id=${id}`),
    ArchiveUser: (id: string): string => buildApiUrl(`identity/archiveUser?id=${id}`),
    RestoreUser: (id: string): string => buildApiUrl(`identity/restoreUser?id=${id}`),
    DeleteInvitedUser: (id: string): string => buildApiUrl(`identity/deleteInvitedUser/${id}`),
    DeleteUser: (id: string): string => buildApiUrl(`identity/deleteUser?id=${id}`),
    GetTheme: buildApiUrl('identity/userTheme'),
    UpdateTheme: buildApiUrl('identity/userTheme'),
  },
  RequestToken: {
    SendEmailConfirmationRequest: buildApiUrl('requestToken/sendEmailConfirmationRequest'),
    SendResetPasswordRequest: buildApiUrl('requestToken/sendResetPasswordRequest'),
    CheckResetPasswordToken: (token: string): string => buildApiUrl(`requestToken/checkResetPasswordToken/${token}`),
    SendRegistrationInvitationRequests: buildApiUrl('requestToken/sendRegistrationInvitationRequests'),
    CheckRegistrationInvitationToken: (token: string): string => buildApiUrl(`requestToken/checkRegistrationInvitationToken/${token}`),
    SendChangeEmailRequest: (id: string): string => buildApiUrl(`requestToken/sendChangeEmailRequest?id=${id}`),
  },
  Company: {
    GetCompanyInfo: buildApiUrl('company'),
    GetCompanyGeneralInfo: buildApiUrl('company/generalInfo'),
    UpdateCompanyGeneralInfo: buildApiUrl('company/generalInfo'),
    GetCompanyLanguages: (companyId: string): string => buildApiUrl(`company/${companyId}/languages`),
    GetSubDomainByCompanyId: (companyId: string): string => buildApiUrl(`company/subDomain/${companyId}`),
    UpdateSubDomain: buildApiUrl(`company/subDomain`),
    CheckSubDomainUniqueness: (subDomain: string): string => buildApiUrl(`company/checkSubDomainUniqueness/${subDomain}`),
    ArchiveCompany: buildApiUrl(`company/archive`),
    RestoreCompany: buildApiUrl(`company/restore`),
  },
  Property: {
    GetProperty: (id: string): string => buildApiUrl(`property/${id}`),
    GetPropertyPreview: (id: string): string => buildApiUrl(`property/preview/${id}`),
    CreateProperty: buildApiUrl('property'),
    UpdateProperty: (authorId: string): string => buildApiUrl(`property?authorId=${authorId}`),
    GetPropertyTexts: (id: string, authorId: string): string => buildApiUrl(`property/${id}/propertyTexts?authorId=${authorId}`),
    UpdatePropertyTexts: (id: string, authorId: string): string => buildApiUrl(`property/${id}/propertyTexts?authorId=${authorId}`),
    GetProperties: buildApiUrl('property/list'),
    GetPropertiesMapItems: buildApiUrl('property/mapItems'),
  },
  Description: {
    CreateDescription: buildApiUrl('description'),
    UpdateDescription: (authorId?: string): string => buildApiUrl(`description?authorId=${authorId}`),
    DeleteDescription: (id: string, authorId?: string): string => buildApiUrl(`description/${id}?authorId=${authorId}`),
    GetDescriptions: buildApiUrl('description'),
    GetDescriptionById: (id: string): string => buildApiUrl(`description/${id}`),
    GetDescriptionsWithoutTranslation: buildApiUrl('description/withoutTranslation'),
    GetNumberOfDescriptionsWithoutTranslation: buildApiUrl('description/numberOfDescriptionsWithoutTranslation'),
  },
  Geocoding: {
    Search: (searchTerm: string): string => buildGeocodingApiUrl(`api/?q=${searchTerm}&limit=5`),
    Reverse: (lon: number, lat: number): string => buildGeocodingApiUrl(`reverse?lon=${lon}&lat=${lat}`),
    ProxySearch: (searchTerm: string): string => buildApiUrl(`geocoding/search?searchTerm=${searchTerm}`),
  },
  Storage: {
    UploadFile: buildApiUrl('storage/file'),
  },
};
