import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CompanyGeneralInfoDtoModel } from '@hesti/models/company/company-general-info/company-general-info.dto-model';
import { ApiConst } from '@portal/constants/api.const';
import { Observable } from 'rxjs';
import { CompanyLogoDtoModel } from '@hesti/models/company/company-logo/company-logo.dto-model';

@Injectable({ providedIn: 'root' })
export class CompanyApiService {
  public constructor(private readonly httpClient: HttpClient) {}

  public getCompanyGeneralInfoByCompanySubDomain(subDomain: string): Observable<CompanyGeneralInfoDtoModel> {
    return this.httpClient.get<CompanyGeneralInfoDtoModel>(ApiConst.Company.GetCompanyGeneralInfoByCompanySubDomain(subDomain));
  }

  public updateCompanyLogos(logos: CompanyLogoDtoModel[]): Observable<void> {
    return this.httpClient.post<void>(ApiConst.Company.UpdateLogos, logos);
  }
}
