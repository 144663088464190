import { Action, Selector, State, StateContext } from '@ngxs/store';
import { LanguageStateModel } from '@portal/store/language/language.state.model';
import { StoreConst } from '@portal/constants/store.const';
import { LocalStorageService } from '@hesti/services/local-storage/local-storage.service';
import { TranslateService } from '@ngx-translate/core';
import { LanguageStateActions } from '@portal/store/language/language.state.actions';
import { PortalLanguage } from '@hesti/constants/enums/language/portal-language.enum';
import { Injectable } from '@angular/core';

@State<LanguageStateModel>({
  name: StoreConst.LanguageState,
  defaults: {
    languages: undefined,
    language: undefined,
  },
})
@Injectable()
export class LanguageState {
  public constructor(
    private readonly localStorageService: LocalStorageService,
    private readonly translateService: TranslateService,
  ) {}

  @Selector()
  public static language({ language }: LanguageStateModel): PortalLanguage {
    return language!;
  }

  @Selector()
  public static languages({ languages }: LanguageStateModel): PortalLanguage[] | undefined {
    return languages;
  }

  @Action(LanguageStateActions.SetLanguages)
  public setLanguages(
    { patchState }: StateContext<LanguageStateModel>,
    { languages, defaultLanguage }: LanguageStateActions.SetLanguages,
  ): void {
    const userSavedLanguage = this.localStorageService.get<PortalLanguage>(StoreConst.LanguageState);

    const defaultUserLanguage = userSavedLanguage && languages.includes(userSavedLanguage) ? userSavedLanguage : defaultLanguage;

    this.translateService.addLangs(languages);
    this.translateService.defaultLang = defaultUserLanguage;
    this.translateService.use(defaultUserLanguage);

    patchState({
      languages,
      language: defaultUserLanguage,
    });
  }

  @Action(LanguageStateActions.SetLanguage)
  public setLanguage({ patchState }: StateContext<LanguageStateModel>, { language }: LanguageStateActions.SetLanguage): void {
    this.translateService.use(language);
    this.localStorageService.set(StoreConst.LanguageState, language);
    patchState({ language });
  }
}
