import { TranslatePipe } from '@ngx-translate/core';
import { Pipe, PipeTransform } from '@angular/core';
import { PortalTranslationKey } from '@portal/models/portal-translation-key.model';

@Pipe({
  standalone: true,
  name: 'portalTranslate',
  pure: false,
})
export class PortalTranslatePipe extends TranslatePipe implements PipeTransform {
  public override transform(query: PortalTranslationKey, ...args: any[]): string {
    return super.transform(query, ...args);
  }
}
