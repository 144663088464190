import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostBinding,
  Input,
  OnInit,
} from '@angular/core';
import { NgxSpinnerService, NgxSpinnerComponent } from 'ngx-spinner';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngxs/store';
import { ThemeState } from '@hesti/store/theme/theme.state';

@UntilDestroy()
@Component({
  standalone: true,
  selector: 'ha-spinner',
  templateUrl: 'spinner.component.html',
  styleUrls: ['./spinner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgxSpinnerComponent],
})
export class SpinnerComponent implements OnInit, AfterViewInit {
  @Input() public name: string;
  @Input() public label: string = '';
  @Input() public size: 'small' | 'default' | 'medium' | 'large' = 'default';
  @Input() public zIndex: number = 99999;
  @Input() public template: string = '';
  @Input() public disableAnimation: boolean = false;
  @Input()
  @HostBinding('class.full-screen')
  public fullScreen: boolean = true;
  @Input() public isLocalBackdropDisplayed: boolean = false;

  @Input() public showOnInit: boolean = false;

  private readonly backdropColor: string = 'rgba(0, 0, 0, 0.63)';
  private readonly darkLocalBackdropColor: string = 'rgba(255, 255, 255, 0.1)';
  private readonly lightLocalBackdropColor: string = 'rgba(255, 255, 255, 0.63)';

  public get bdColor(): string {
    return this.fullScreen
      ? this.backdropColor
      : this.isLocalBackdropDisplayed
        ? this.store.selectSnapshot(ThemeState.appliedTheme) === 'Dark'
          ? this.darkLocalBackdropColor
          : this.lightLocalBackdropColor
        : 'rgba(0, 0, 0, 0)';
  }

  public constructor(
    private readonly spinnerService: NgxSpinnerService,
    private readonly changeDetector: ChangeDetectorRef,
    private readonly elementRef: ElementRef,
    private readonly store: Store,
  ) {}

  public ngOnInit(): void {
    this.spinnerService
      .getSpinner(this.name)
      .pipe(untilDestroyed(this))
      .subscribe(({ show }) => {
        if (show) {
          this.elementRef.nativeElement.classList.add('displayed');
        } else {
          this.elementRef.nativeElement.classList.remove('displayed');
        }
        this.changeDetector.detectChanges();
      });
  }

  public ngAfterViewInit(): void {
    if (this.showOnInit) {
      this.spinnerService.show(this.name);
    }
  }
}
