export enum Permission {
  ManageCompany = 'ManageCompany',
  DeleteCompany = 'DeleteCompany',
  ManageSubscription = 'ManageSubscription',
  InviteNewUser = 'InviteNewUser',
  MangeUser = 'MangeUser',
  ManageUserRole = 'ManageUserRole',
  ManageDescription = 'ManageDescription',
  ArchiveUser = 'ArchiveUser',
  ManageProperty = 'ManageProperty',
}
