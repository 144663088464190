import { Breakpoints } from '@angular/cdk/layout';

export enum DeviceClass {
  Mobile = 'mobile',
  Desktop = 'desktop',
}

export enum Breakpoint {
  Handset = 'Handset',
  Tablet = 'Tablet',
  Web = 'Web',
  Unknown = 'Unknown',
}

export const MobileBreakpoints = [Breakpoint.Handset, Breakpoint.Tablet];

export const ObservedBreakpoints = [
  Breakpoints.Handset,
  Breakpoints.HandsetPortrait,
  Breakpoints.HandsetLandscape,
  Breakpoints.Tablet,
  Breakpoints.TabletPortrait,
  Breakpoints.TabletLandscape,
  Breakpoints.Web,
  Breakpoints.WebPortrait,
  Breakpoints.WebLandscape,
];

export const BreakpointNameMap = new Map([
  [Breakpoints.Handset, Breakpoint.Handset],
  [Breakpoints.HandsetPortrait, Breakpoint.Handset],
  [Breakpoints.HandsetLandscape, Breakpoint.Handset],
  [Breakpoints.Tablet, Breakpoint.Tablet],
  [Breakpoints.TabletPortrait, Breakpoint.Tablet],
  [Breakpoints.TabletLandscape, Breakpoint.Tablet],
  [Breakpoints.Web, Breakpoint.Web],
  [Breakpoints.WebPortrait, Breakpoint.Web],
  [Breakpoints.WebLandscape, Breakpoint.Web],
]);
