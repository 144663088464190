import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Theme } from '@hesti/models/theme/theme.model';

@Injectable({ providedIn: 'root' })
export class BrowserThemingService implements OnDestroy {
  private darkModeMediaQuery = '(prefers-color-scheme: dark)';

  public readonly theme$ = new BehaviorSubject<Theme>('Light');

  public constructor() {
    if (window.matchMedia) {
      const isDarkThemeOn = window.matchMedia(this.darkModeMediaQuery).matches;
      this.theme$.next(isDarkThemeOn ? 'Dark' : 'Light');

      window.matchMedia(this.darkModeMediaQuery).addEventListener('change', this.updateBrowserTheme);
    }
  }

  public ngOnDestroy(): void {
    window.matchMedia(this.darkModeMediaQuery).removeEventListener('change', this.updateBrowserTheme);
  }

  private updateBrowserTheme = (event: MediaQueryListEvent): void => {
    this.theme$.next(event.matches ? 'Dark' : 'Light');
  };
}
