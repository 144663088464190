export enum Icon {
  Language = 'language',
  Check = 'check',
  Mail = 'mail',
  Visibility = 'visibility',
  VisibilityOff = 'visibility_off',
  Close = 'close',
  Remove = 'remove',
  SpaceDashboard = 'space_dashboard',
  Villa = 'villa',
  Group = 'Group',
  CardMembership = 'card_membership',
  Person = 'person',
  ChevronRight = 'chevron_right',
  ChevronLeft = 'chevron_left',
  DarkMode = 'dark_mode',
  LightMode = 'light_mode',
  LocationOn = 'location_on',
  MyLocation = 'my_location',
  Call = 'call',
  AspectRatio = 'aspect_ratio',
  Floor = 'floor',
  Dashboard = 'dashboard',
  Crop = 'crop',
  Add = 'Add',
  PageInfo = 'page_info',
  GridView = 'grid_view',
  Search = 'search',
  SwapVert = 'swap_vert',
  SwapHoriz = 'swap_horiz',
  SearchOff = 'search_off',
  Done = 'done',
  Delete = 'delete',
  Edit = 'edit',
  EditSquare = 'edit_square',
  Logout = 'logout',
  Link = 'link',
  Badge = 'badge',
  AdminPanelSettings = 'admin_panel_settings',
  Settings = 'settings',
  UploadFile = 'upload_file',
  Reorder = 'reorder',
  PermPhoneMsg = 'perm_phone_msg',
  Help = 'help',
  Refresh = 'refresh',
  Verified = 'verified',
  EditorChoice = 'editor_choice',
  ShieldPerson = 'shield_person',
  ProgressActivity = 'progress_activity',
  Send = 'send',
  GroupAdd = 'group_add',
  GroupOff = 'group_off',
  Translate = 'translate',
  FormatBold = 'format_bold',
  FormatItalic = 'format_italic',
  FormatUnderlined = 'format_underlined',
  FormatStrikethrough = 'format_strikethrough',
  FormatQuote = 'format_quote',
  FormatAlignLeft = 'format_align_left',
  FormatAlignCenter = 'format_align_center',
  FormatAlignRight = 'format_align_right',
  FormatAlignJustify = 'format_align_justify',
  FormatListBulleted = 'format_list_bulleted',
  FormatListNumbered = 'format_list_numbered',
  FormatClear = 'format_clear',
  AddLocation = 'add_location',
  Home = 'home',
  Today = 'today',
  PhotoAutoMerge = 'photo_auto_merge',
  Save = 'save',
  Dictionary = 'dictionary',
  Wifi = 'wifi',
  Tv = 'tv',
  DeskPhone = 'deskphone',
  Blinds = 'blinds',
  Key = 'key',
  Lan = 'lan',
  Videocam = 'videocam',
  Encrypted = 'encrypted',
  Pageless = 'pageless',
  Bed = 'bed',
  DishwasherGen = 'dishwasher_gen',
  Dishwasher = 'dishwasher',
  Kitchen = 'kitchen',
  Fireplace = 'fireplace',
  Oven = 'oven',
  AcUnit = 'ac_unit',
  Balcony = 'balcony',
  SplitscreenPortrait = 'splitscreen_portrait',
  RoomPreferences = 'room_preferences',
  Warehouse = 'warehouse',
  OutdoorGarden = 'outdoor_garden',
  Deck = 'deck',
  Elevator = 'elevator',
  WifiHome = 'wifi_home',
  HeatPumpBalance = 'heat_pump_balance',
  House = 'house',
  PhotoLibrary = 'photo_library',
  Warning = 'warning',
  Archive = 'archive',
  Unarchive = 'unarchive',
  MoreVert = 'more_vert',
  ExitToApp = 'exit_to_app',
  SwipeUp = 'swipe_up',
  Foundation = 'foundation',
  OpenInNew = 'open_in_new',
  AddAPhoto = 'add_a_photo',
  Palette = 'palette',
  Lock = 'lock',
  Replay30 = 'replay_30',
  Sell = 'sell',
  LooksOne = 'Looks_one',
  LooksTwo = 'looks_two',
}
