import { PortalLanguage } from '@hesti/constants/enums/language/portal-language.enum';

export namespace LanguageStateActions {
  export class SetLanguage {
    public static readonly type: string = '[Language] SetLanguage';

    public constructor(public readonly language: PortalLanguage) {}
  }

  export class SetLanguages {
    public static readonly type: string = '[Language] SetLanguages';

    public constructor(
      public readonly languages: PortalLanguage[],
      public readonly defaultLanguage: PortalLanguage,
    ) {}
  }
}
