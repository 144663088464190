import { AbstractControl, ValidatorFn } from '@angular/forms';
import { ValidationError } from '@hesti/models/validation-error.model';
import { CommonTranslationKey } from '@hesti/models/translation-key.model';
import { isNil } from 'lodash-es';

export type CompareNumbersOperator = (verifiableNumber: number | null, comparedNumber: number | null) => boolean;

export interface CompareNumbersValidatorConfig {
  operator: CompareNumbersOperator;
  messageKey: CommonTranslationKey;
}

export class CompareNumbersValidator {
  public static compareNumbers =
    <TModel>(
      verifiableNumberControlName: keyof TModel,
      comparedNumberControlName: keyof TModel,
      { messageKey, operator }: CompareNumbersValidatorConfig,
    ): ValidatorFn =>
    (formGroup: AbstractControl): ValidationError | null => {
      const verifiableNumberControl = formGroup.get(verifiableNumberControlName as string);
      const verifiableNumber = verifiableNumberControl?.value;
      const comparedNumberControl = formGroup.get(comparedNumberControlName as string);
      const comparedNumber = comparedNumberControl?.value;

      const validationError = new ValidationError(messageKey, { verifiableNumber, comparedNumber });
      verifiableNumberControl!.updateValueAndValidity({ onlySelf: true });
      verifiableNumberControl!.setErrors(operator(verifiableNumber, comparedNumber) ? null : validationError);
      return null;
    };

  public static compareNumbersRange =
    <TModel>(
      verifiableNumberControlName: keyof TModel,
      comparedNumberControlName: keyof TModel,
      { messageKey, operator }: CompareNumbersValidatorConfig,
    ): ValidatorFn =>
    (formGroup: AbstractControl): ValidationError | null => {
      const verifiableNumberControl = formGroup.get(verifiableNumberControlName as string);
      const verifiableNumber = verifiableNumberControl?.value;
      const comparedNumberControl = formGroup.get(comparedNumberControlName as string);
      const comparedNumber = comparedNumberControl?.value;

      const validationError = new ValidationError(messageKey, { verifiableNumber, comparedNumber });
      verifiableNumberControl!.updateValueAndValidity({ onlySelf: true });
      verifiableNumberControl!.setErrors(operator(verifiableNumber, comparedNumber) ? null : { incorrect: true });
      return operator(verifiableNumber, comparedNumber) ? null : validationError;
    };

  public static lessOrEqualThanComparerConfig = (): CompareNumbersValidatorConfig => ({
    operator: (verifiableNumber: number | null, comparedNumber: number | null): boolean => {
      if (isNil(comparedNumber) || isNil(verifiableNumber)) {
        return true;
      }

      return verifiableNumber <= comparedNumber;
    },
    messageKey: 'api.validationError.lessOrEqualThan',
  });
}
