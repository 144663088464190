import { ChangeDetectionStrategy, Component } from '@angular/core';
import { BaseApiErrorComponent } from '@portal/components/api-error/base-api-error.component';
import { PortalTranslatePipe } from '@portal/pipes/translate/portal-translate.pipe';

@Component({
  standalone: true,
  selector: 'hp-internal-server-error',
  templateUrl: './internal-server-error.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [PortalTranslatePipe],
})
export class InternalServerErrorComponent extends BaseApiErrorComponent {}
