import { SignInInfoModel } from '@hesti/models/identity/sign-in-info/sign-in-info.model';
import { JwtInfoModel } from '@hesti/models/jwt-info/jwt-info.model';

export namespace UserStateActions {
  const prefix = '[User]';

  export class SignIn {
    public static readonly type: string = `${prefix} Sign In`;

    public constructor(public readonly signInInfo: SignInInfoModel) {}
  }

  export class ResetApiError {
    public static readonly type: string = `${prefix} ResetApiError`;
  }

  export class SignInWithGoogle {
    public static readonly type: string = `${prefix} Sign In With Google`;

    public constructor(public readonly credential: string) {}
  }

  export class RefreshToken {
    public static readonly type: string = `${prefix} Refresh Token`;
  }

  export class SignOut {
    public static readonly type: string = `${prefix} Sign Out`;
  }

  export class SetJwtInfo {
    public static readonly type: string = `${prefix} Set Jwt Info`;

    public constructor(public readonly jwtInfo: JwtInfoModel) {}
  }
}
