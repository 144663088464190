import { Inject, Injectable } from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { map, Observable } from 'rxjs';
import { Breakpoint, BreakpointNameMap, DeviceClass, MobileBreakpoints, ObservedBreakpoints } from '@hesti/constants/breakpoint.const';
import { DOCUMENT } from '@angular/common';

@UntilDestroy()
@Injectable({ providedIn: 'root' })
export class ScreenObserverService {
  private _currentSize: Breakpoint | null;
  private currentDeviceClass: DeviceClass | undefined;

  public get currentSize(): Breakpoint | null {
    return this._currentSize;
  }

  public get isMobileDevice$(): Observable<boolean> {
    return this.currentSize$.pipe(map((currentSize) => !!currentSize && MobileBreakpoints.includes(currentSize)));
  }

  public get isMobileDevice(): boolean {
    return !!this.currentSize && MobileBreakpoints.includes(this.currentSize);
  }

  public get deviceClass$(): Observable<DeviceClass> {
    return this.isMobileDevice$.pipe(map((isMobile) => (isMobile ? DeviceClass.Mobile : DeviceClass.Desktop)));
  }

  public get deviceClass(): DeviceClass {
    return this.isMobileDevice ? DeviceClass.Mobile : DeviceClass.Desktop;
  }

  public currentSize$: Observable<Breakpoint | null>;

  public constructor(
    private readonly breakpointObserver: BreakpointObserver,
    @Inject(DOCUMENT) private readonly document: Document,
  ) {
    this.currentSize$ = this.breakpointObserver.observe(ObservedBreakpoints).pipe(
      map((result) => {
        for (const query of Object.keys(result.breakpoints)) {
          if (result.breakpoints[query]) {
            return BreakpointNameMap.get(query) ?? Breakpoint.Unknown;
          }
        }

        return null;
      }),
    );
    this.currentSize$.pipe(untilDestroyed(this)).subscribe((currentSize) => (this._currentSize = currentSize));
    this.deviceClass$.pipe(untilDestroyed(this)).subscribe(this.toggleRootDeviceClass);
  }

  private toggleRootDeviceClass = (deviceClass: DeviceClass): void => {
    if (this.currentDeviceClass) {
      this.document.documentElement.classList.remove(this.currentDeviceClass);
    }
    this.currentDeviceClass = deviceClass;
    this.document.documentElement.classList.add(this.currentDeviceClass);
  };
}
