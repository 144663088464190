import { JwtUserInfoModel } from '@hesti/models/jwt-user-info/jwt-user-info.model';
import { JwtUserInfoDtoModel } from '@hesti/models/jwt-user-info/jwt-user-info.dto-model';
import { UserInfoUtils } from '@hesti/utils/user-info.utils';
import { DateMapper } from '@hesti/models/base/date.mapper';

export class JwtUserInfoMapper {
  public static toModel(dto: JwtUserInfoDtoModel): JwtUserInfoModel {
    return {
      Id: dto.Id,
      CompanyId: dto.CompanyId,
      IsCompanyArchived: dto.IsCompanyArchived.toLocaleLowerCase() === 'true',
      CompanyArchivePeriodEnd: DateMapper.toModel(dto.CompanyArchivePeriodEnd),
      Email: dto.Email,
      FirstName: dto.FirstName,
      LastName: dto.LastName,
      UserName: UserInfoUtils.getUserName(dto.FirstName, dto.LastName, dto.Email),
      Role: dto.Role,
      Permissions: dto.Permissions,
      PermissionsScope: JSON.parse(dto.PermissionsScope),
      AvatarUrl: UserInfoUtils.getAvatarUrl(dto.AvatarUrl),
    };
  }
}
