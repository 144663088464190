import { AbstractControl, ValidatorFn, Validators } from '@angular/forms';
import { ValidationError } from '@hesti/models/validation-error.model';
import { CommonTranslationKey } from '@hesti/models/translation-key.model';

export class MaxLengthValidator {
  public static maxLength =
    (maxLength: number, messageKey: CommonTranslationKey | null = 'api.validationError.maxLength'): ValidatorFn =>
    (control: AbstractControl): ValidationError | null => {
      const currentLength = control.value?.length || 0;
      const validationError = new ValidationError(messageKey, { currentLength, maxLength });
      return Validators.maxLength(maxLength)(control) ? validationError : null;
    };
}
