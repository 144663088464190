import { Directive, ElementRef, OnInit } from '@angular/core';
import { ScreenObserverService } from '@hesti/services/screen-observer/screen-observer.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DeviceClass } from '@hesti/constants/breakpoint.const';

@UntilDestroy()
@Directive({
  standalone: true,
  selector: '[haDeviceClass]',
})
export class DeviceClassDirective implements OnInit {
  private currentDeviceClass: DeviceClass | undefined;

  private get element(): Element {
    return this.elementRef.nativeElement;
  }

  public constructor(
    private readonly screenObserverService: ScreenObserverService,
    private readonly elementRef: ElementRef,
  ) {}

  public ngOnInit(): void {
    this.screenObserverService.deviceClass$.pipe(untilDestroyed(this)).subscribe(this.toggleClass);
  }

  private toggleClass = (deviceClass: DeviceClass): void => {
    if (this.currentDeviceClass) {
      this.element.classList.remove(this.currentDeviceClass);
    }
    this.currentDeviceClass = deviceClass;
    this.element.classList.add(this.currentDeviceClass);
  };
}
