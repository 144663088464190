import { AbstractControl, ValidatorFn, Validators } from '@angular/forms';
import { ValidationError } from '@hesti/models/validation-error.model';
import { CommonTranslationKey } from '@hesti/models/translation-key.model';

export class RequiredHtmlValidator {
  public static requiredHtml =
    (messageKey: CommonTranslationKey | null = 'api.validationError.required'): ValidatorFn =>
    (control: AbstractControl): ValidationError | null => {
      const validationError = new ValidationError(messageKey);
      return Validators.required(control) || control.value === '<p></p>' ? validationError : null;
    };
}
