import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NgIf, NgStyle } from '@angular/common';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { Icon } from './icon.enum';

@Component({
  standalone: true,
  selector: 'ha-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgIf, NgStyle, FaIconComponent],
})
export class IconComponent {
  @Input() public icon?: Icon;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() public faIcon?: any;
  @Input() public size: number = 15;
  // eslint-disable-next-line @typescript-eslint/no-magic-numbers
  @Input() public weight: 100 | 200 | 300 | 400 | 500 | 600 | 700 = 400;
  @Input() public filled = false;

  public get style(): object {
    return {
      'font-size': `${this.size}px`,
      'font-variation-settings': `'FILL' ${this.filled ? 1 : 0}, 'wght' ${this.weight}`,
    };
  }
}
