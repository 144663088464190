import { Action, NgxsOnInit, Selector, State, StateContext, Store } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { LocalStorageService } from '@hesti/services/local-storage/local-storage.service';
import { combineLatest, map, Observable } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Theme } from '@hesti/models/theme/theme.model';
import { BrowserThemingService } from '@hesti/services/browser-theming/browser-theming.service';
import { StoreConst } from '@portal/constants/store.const';
import { ThemeService } from '@hesti/services/theme/theme.service';
import { ThemeStateModel } from '@portal/features/design-core/store/theme/theme.state.model';
import { ThemeStateActions } from '@portal/features/design-core/store/theme/theme.state.actions';
import { ApiErrorDtoModel } from '@hesti/models/api-error/dto/api-error.dto-model';
import { ApiErrorModel } from '@hesti/models/api-error/api-error.model';
import { SpinnerService } from '@hesti/services/spinner/spinner.service';
import { ThemeMode } from '@hesti/models/theme/theme-mode.model';
import { CompanyInfoState } from '@portal/store/company-info/company-info.state';
import { filter } from 'rxjs/operators';

@UntilDestroy()
@State<ThemeStateModel>({
  name: StoreConst.ThemeState,
  defaults: {
    theme: 'Light',
    isToggleDisplayed: false,
    isLoading: true,
    apiError: undefined,
    forcedTheme: undefined,
  },
})
@Injectable()
export class ThemeState implements NgxsOnInit {
  public constructor(
    private readonly browserThemingService: BrowserThemingService,
    private readonly localStorageService: LocalStorageService,
    private readonly themeService: ThemeService,
    private readonly spinnerService: SpinnerService,
    private readonly store: Store,
  ) {}

  @Selector()
  public static theme({ theme }: ThemeStateModel): Theme {
    return theme;
  }

  @Selector()
  public static isDarkTheme({ theme }: ThemeStateModel): boolean {
    return theme === 'Dark';
  }

  @Selector()
  public static isToggleDisplayed({ isToggleDisplayed }: ThemeStateModel): boolean {
    return isToggleDisplayed;
  }

  @Selector()
  public static isLoading({ isLoading }: ThemeStateModel): boolean {
    return isLoading;
  }

  @Selector()
  public static apiError({ apiError }: ThemeStateModel): ApiErrorModel | undefined {
    return apiError;
  }

  @Selector()
  public static forcedTheme({ forcedTheme }: ThemeStateModel): Theme | undefined {
    return forcedTheme;
  }

  @Action(ThemeStateActions.ToggleTheme)
  public toggleTheme(context: StateContext<ThemeStateModel>): void {
    const { forcedTheme } = context.getState();
    if (forcedTheme) {
      return;
    }

    let { theme } = context.getState();
    theme = theme === 'Light' ? 'Dark' : 'Light';
    this.updateAndSaveTheme(context, theme);
  }

  @Action(ThemeStateActions.SetForcedTheme)
  public setForcedTheme(context: StateContext<ThemeStateModel>, { forcedTheme }: ThemeStateActions.SetForcedTheme): void {
    context.patchState({ forcedTheme });
  }

  public ngxsOnInit(context: StateContext<ThemeStateModel>): void {
    this.spinnerService.show();
    this.subscribeToThemeDependencies(context);
  }

  private subscribeToThemeDependencies(context: StateContext<ThemeStateModel>): void {
    const dependencies$: Observable<[ThemeMode, Theme, Theme | undefined]> = combineLatest([
      this.store.select(CompanyInfoState.companyGeneralInfo).pipe(
        filter(Boolean),
        map(({ themeMode }) => themeMode),
      ),
      this.browserThemingService.theme$.asObservable(),
      this.store.select(ThemeState.forcedTheme),
    ]);

    dependencies$.pipe(untilDestroyed(this)).subscribe({
      next: ([themeMode, browserTheme, forcedTheme]: [ThemeMode, Theme, Theme | undefined]) =>
        this.updateState(context, themeMode, browserTheme, forcedTheme),
      error: (error) => this.setErrorState(context, error),
    });
  }

  private updateState(
    context: StateContext<ThemeStateModel>,
    themeMode: ThemeMode,
    browserTheme: Theme,
    forcedTheme: Theme | undefined,
  ): void {
    const theme = this.getTheme(themeMode, browserTheme, forcedTheme);
    this.updateTheme(context, theme);
    this.updateToggleVisibility(context, themeMode);
    context.patchState({ apiError: undefined, isLoading: false });
    this.spinnerService.hide();
  }

  private getTheme(themeMode: ThemeMode, browserTheme: Theme, forcedTheme: Theme | undefined): Theme {
    if (forcedTheme) {
      return forcedTheme;
    }

    if (themeMode === ThemeMode.Browser) {
      return browserTheme;
    }
    if (themeMode === ThemeMode.Select) {
      const savedTheme = this.localStorageService.get<Theme>(StoreConst.ThemeState);
      return savedTheme || browserTheme;
    }
    return themeMode === ThemeMode.Light ? 'Light' : 'Dark';
  }

  private updateToggleVisibility(context: StateContext<ThemeStateModel>, themeMode: ThemeMode): void {
    const isToggleDisplayed = themeMode === ThemeMode.Select;
    context.patchState({ isToggleDisplayed });
  }

  private updateTheme(context: StateContext<ThemeStateModel>, theme: Theme): void {
    this.themeService.applyTheme(theme);
    context.patchState({ theme });
  }

  private updateAndSaveTheme(context: StateContext<ThemeStateModel>, theme: Theme): void {
    this.updateTheme(context, theme);
    this.localStorageService.set(StoreConst.ThemeState, theme);
  }

  private setErrorState = (context: StateContext<ThemeStateModel>, errorDto: ApiErrorDtoModel): void => {
    context.patchState({ apiError: new ApiErrorModel(errorDto), isLoading: false });
    this.spinnerService.hide();
  };
}
