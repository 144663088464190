import { PortalLanguage } from '@hesti/constants/enums/language/portal-language.enum';
import { AdminLanguage } from '@hesti/constants/enums/language/admin-language.enum';
import { CommonTranslationKey, TranslationKey } from '@hesti/models/translation-key.model';

export class LanguageUtils {
  public static parsePortalLanguage(language: string): PortalLanguage {
    if (!(Object.values(PortalLanguage) as string[]).includes(language)) {
      // eslint-disable-next-line no-console
      console.error(this.getErrorMessage(language));

      return PortalLanguage.EN;
    }

    return language as PortalLanguage;
  }

  public static parseAdminLanguage(language: string): AdminLanguage {
    if (!(Object.values(AdminLanguage) as string[]).includes(language)) {
      // eslint-disable-next-line no-console
      console.error(this.getErrorMessage(language));

      return AdminLanguage.EN;
    }

    return language as AdminLanguage;
  }

  public static getPortalLanguageTranslationKey(language: PortalLanguage): CommonTranslationKey {
    return `portalLanguages.${language}`;
  }

  public static getAdminLanguageTranslationKey(language: AdminLanguage): TranslationKey {
    return `adminLanguages.${language}`;
  }

  public static compareLanguages(a: PortalLanguage, b: PortalLanguage): number {
    if (a < b) {
      // eslint-disable-next-line @typescript-eslint/no-magic-numbers
      return -1;
    }
    if (a > b) {
      return 1;
    }
    return 0;
  }

  private static getErrorMessage(language: string): string {
    return `Unknown language code ${language}`;
  }
}
