import { JwtInfoDtoModel } from '@hesti/models/jwt-info/jwt-info.dto-model';
import { jwtDecode } from 'jwt-decode';
import { JwtUserInfoMapper } from '@hesti/models/jwt-user-info/jwt-user-info.mapper';
import { JwtUserInfoModel } from '@hesti/models/jwt-user-info/jwt-user-info.model';

export class JwtInfoModel {
  public readonly userInfo: JwtUserInfoModel;
  public readonly accessToken: string;
  public readonly refreshToken: string;

  public constructor(dto: JwtInfoDtoModel) {
    this.userInfo = JwtUserInfoMapper.toModel(jwtDecode(dto.accessToken));
    this.accessToken = dto.accessToken;
    this.refreshToken = dto.refreshToken;
  }
}
