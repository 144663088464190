import { AbstractControl, ValidatorFn } from '@angular/forms';
import { CommonTranslationKey } from '@hesti/models/translation-key.model';
import { ValidationError } from '../../models/validation-error.model';

export class EqualToValidator {
  public static equalTo =
    <T>(messageKey: CommonTranslationKey | null, comparisonValue: T): ValidatorFn =>
    (control: AbstractControl): ValidationError | null => {
      const validationError = new ValidationError(messageKey);
      return control.value !== comparisonValue ? validationError : null;
    };
}
