import { AdminLanguage } from '@hesti/constants/enums/language/admin-language.enum';

export namespace LanguageStateActions {
  const prefix = '[Language]';

  export class SetLanguage {
    public static readonly type: string = `${prefix} SetLanguage`;

    public constructor(public readonly language: AdminLanguage) {}
  }

  export class SetAuthenticatedUserLanguage {
    public static readonly type: string = `${prefix} Set Authenticated User Language`;

    public constructor(public readonly language: AdminLanguage) {}
  }

  export class LoadAuthenticatedUserLanguage {
    public static readonly type: string = `${prefix} Load Authenticated User Language`;
  }

  export class RestoreLanguage {
    public static readonly type: string = `${prefix} Restore Language`;
  }
}
