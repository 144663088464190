import { AbstractControl, AsyncValidatorFn } from '@angular/forms';
import { map, Observable } from 'rxjs';
import { ValidationError } from '@hesti/models/validation-error.model';
import { CommonTranslationKey } from '@hesti/models/translation-key.model';

export class UniquenessAsyncValidator {
  public static uniquenessAsync =
    <T>(messageKey: CommonTranslationKey, apiMethod: (param: T) => Observable<boolean>): AsyncValidatorFn =>
    (control: AbstractControl): Observable<ValidationError | null> =>
      apiMethod(control.value).pipe(map((isUnique: boolean) => (isUnique ? null : new ValidationError(messageKey))));
}
