import { AbstractControl, ValidatorFn, Validators } from '@angular/forms';
import { CommonTranslationKey } from '@hesti/models/translation-key.model';
import { ValidationError } from '../../models/validation-error.model';

export class PatternValidator {
  public static pattern =
    (messageKey: CommonTranslationKey | null, pattern: RegExp | string): ValidatorFn =>
    (control: AbstractControl): ValidationError | null => {
      const validationError = new ValidationError(messageKey);
      return Validators.pattern(pattern)(control) ? validationError : null;
    };
}
