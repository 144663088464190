import { Action, NgxsOnInit, Selector, State, StateContext, Store } from '@ngxs/store';
import { AdminStoreConst } from '@hesti/features/admin/constants/admin-store.const';
import { ThemeStateModel } from '@hesti/store/theme/theme.state.model';
import { Injectable } from '@angular/core';
import { Theme } from '@hesti/models/theme/theme.model';
import { ThemeStateActions } from '@hesti/store/theme/theme.state.actions';
import { combineLatest, finalize, Observable, tap } from 'rxjs';
import { IdentityApiService } from '@hesti/services/api/identity-api.service';
import { SpinnerService } from '@hesti/services/spinner/spinner.service';
import { BrowserThemingService } from '@hesti/services/browser-theming/browser-theming.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ThemeService } from '@hesti/services/theme/theme.service';

@UntilDestroy()
@State<ThemeStateModel>({
  name: AdminStoreConst.ThemeState,
  defaults: { userTheme: undefined, appliedTheme: 'Light' },
})
@Injectable()
export class ThemeState implements NgxsOnInit {
  public constructor(
    private readonly spinnerService: SpinnerService,
    private readonly identityApiService: IdentityApiService,
    private readonly store: Store,
    private readonly browserThemingService: BrowserThemingService,
    private readonly themeService: ThemeService,
  ) {}

  @Selector()
  public static appliedTheme({ appliedTheme }: ThemeStateModel): Theme {
    return appliedTheme;
  }

  @Selector()
  public static userTheme({ userTheme }: ThemeStateModel): Theme | undefined {
    return userTheme;
  }

  @Action(ThemeStateActions.LoadUserTheme)
  public loadUserTheme({ patchState }: StateContext<ThemeStateModel>): Observable<unknown> {
    this.spinnerService.show();

    return this.identityApiService.getUserTheme().pipe(
      tap((userTheme) => patchState({ userTheme })),
      finalize(() => this.spinnerService.hide()),
    );
  }

  @Action(ThemeStateActions.SetUserTheme)
  public setUserTheme({ patchState }: StateContext<ThemeStateModel>, { userTheme }: ThemeStateActions.SetUserTheme): Observable<unknown> {
    return this.identityApiService.updateUserTheme(userTheme).pipe(tap(() => patchState({ userTheme })));
  }

  public ngxsOnInit(context: StateContext<ThemeStateModel>): void {
    this.subscribeToThemeDependencies(context);
  }

  private subscribeToThemeDependencies(context: StateContext<ThemeStateModel>): void {
    const dependencies$ = combineLatest([this.store.select(ThemeState.userTheme), this.browserThemingService.theme$]);
    dependencies$.pipe(untilDestroyed(this)).subscribe(([userTheme, browserTheme]) => this.updateTheme(userTheme, browserTheme, context));
  }

  private updateTheme(userTheme: Theme | undefined, browserTheme: Theme, { patchState }: StateContext<ThemeStateModel>): void {
    const appliedTheme = userTheme || browserTheme;
    patchState({ appliedTheme });
    this.themeService.applyTheme(appliedTheme);
  }
}
