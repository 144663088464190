import { ImageConst } from '@hesti/constants/image.const';
import { Role } from '@hesti/constants/enums/role.enum';
import { Icon } from '@hesti/components/icon/icon.enum';
import { Permission } from '@hesti/constants/enums/permission.enum';
import { CommonTranslationKey, TranslationKey } from '@hesti/models/translation-key.model';
import { JobTitle } from '@hesti/constants/enums/job-title.enum';

export class UserInfoUtils {
  public static getUserName(firstName: string | undefined, lastName: string | undefined, email: string): string {
    return !!firstName || !!lastName ? `${firstName || ''} ${lastName || ''}`.trim() : email;
  }

  public static getAvatarUrl(avatarUrl: string): string {
    return avatarUrl || ImageConst.UserPlaceholder;
  }

  public static getRoleIcon(role: Role): Icon {
    switch (role) {
      case Role.Founder:
        return Icon.Verified;
      case Role.SuperAdministrator:
        return Icon.EditorChoice;
      case Role.Administrator:
        return Icon.ShieldPerson;
      case Role.Agent:
        return Icon.Person;
    }
  }

  public static getRoleTooltipKey(role: Role): TranslationKey {
    return `roles.${role}`;
  }

  public static getPermissionTooltipKey(permission: Permission): TranslationKey {
    return `permissions.${permission}`;
  }

  public static getPermissionIcon(permission: Permission): Icon {
    switch (permission) {
      case Permission.ManageCompany:
        return Icon.Group;
      case Permission.DeleteCompany:
        return Icon.GroupOff;
      case Permission.ManageSubscription:
        return Icon.CardMembership;
      case Permission.InviteNewUser:
        return Icon.GroupAdd;
      case Permission.MangeUser:
        return Icon.Person;
      case Permission.ManageUserRole:
        return Icon.ShieldPerson;
      case Permission.ManageDescription:
        return Icon.PhotoAutoMerge;
      case Permission.ArchiveUser:
        return Icon.Archive;
      case Permission.ManageProperty:
        return Icon.Villa;
    }
  }

  public static getJobTitle(jobTitle: JobTitle): CommonTranslationKey {
    return `jobTitles.${jobTitle}`;
  }
}
