<ngx-spinner
  [bdColor]="bdColor"
  [size]="size"
  [fullScreen]="fullScreen"
  [name]="name"
  [zIndex]="zIndex"
  [template]="template"
  [disableAnimation]="disableAnimation"
>
  <p class="label">{{ label }}</p>
</ngx-spinner>
