import { ValidationError } from '@hesti/models/validation-error.model';
import { AbstractControl, ValidatorFn } from '@angular/forms';
import { CommonTranslationKey } from '@hesti/models/translation-key.model';

export class IntegerValidator {
  public static integer =
    (messageKey: CommonTranslationKey | null = 'api.validationError.mustBeInteger'): ValidatorFn =>
    (control: AbstractControl): ValidationError | null => {
      const validationError = new ValidationError(messageKey);
      return Number.isInteger(control.value) || !control.value ? null : validationError;
    };
}
