import { MaxLengthValidator } from '@hesti/services/form-validators/max-length-validator';
import { UniquenessAsyncValidator } from '@hesti/services/form-validators/uniqueness-async-validator';
import { RequiredIfValidator } from '@hesti/services/form-validators/required-if-validator';
import { UniquenessInArrayValidator } from '@hesti/services/form-validators/uniqueness-in-array-validator';
import { MinValidator } from '@hesti/services/form-validators/min-validator';
import { MaxValidator } from '@hesti/services/form-validators/max-validator';
import { RequiredHtmlValidator } from '@hesti/services/form-validators/required-html-validator';
import { IntegerValidator } from '@hesti/services/form-validators/integer-validator';
import { CompareNumbersValidator } from '@hesti/services/form-validators/compare-numbers-validator';
import { RequiredValidator } from './required-validator';
import { RepeatPasswordValidator } from './repeat-password-validator';
import { PatternValidator } from './pattern-validator';
import { EqualToValidator } from './equal-to-validator';
import { DateRangeValidators } from './date-range-validators';

export const AppValidators = {
  ...EqualToValidator,
  ...PatternValidator,
  ...RepeatPasswordValidator,
  ...RequiredValidator,
  ...MaxLengthValidator,
  ...UniquenessAsyncValidator,
  ...RequiredIfValidator,
  ...UniquenessInArrayValidator,
  ...MinValidator,
  ...MaxValidator,
  ...RequiredHtmlValidator,
  ...IntegerValidator,
  ...CompareNumbersValidator,
  ...DateRangeValidators,
};
