import { Store } from '@ngxs/store';
import * as i0 from '@angular/core';
import { ɵɵdirectiveInject, ChangeDetectorRef, NgModule } from '@angular/core';
class NgxsSelectSnapshotModuleIsNotImported extends Error {
  constructor() {
    super(`You've forgotten to import "NgxsSelectSnapshotModule"!`);
  }
}
let injector = null;
function assertDefined(actual) {
  if (actual == null) {
    throw new NgxsSelectSnapshotModuleIsNotImported();
  }
}
function setInjector(parentInjector) {
  injector = parentInjector;
}
/**
 * Ensure that we don't keep any references in case of the bootstrapped
 * module is destroyed via `NgModuleRef.destroy()`.
 */
function clearInjector() {
  injector = null;
}
function getStore() {
  assertDefined(injector);
  return injector.get(Store);
}
const DOLLAR_CHAR_CODE = 36;
function removeDollarAtTheEnd(name) {
  if (typeof name !== 'string') {
    name = name.toString();
  }
  const lastCharIndex = name.length - 1;
  const dollarAtTheEnd = name.charCodeAt(lastCharIndex) === DOLLAR_CHAR_CODE;
  return dollarAtTheEnd ? name.slice(0, lastCharIndex) : name;
}
function getPropsArray(selectorOrFeature, paths) {
  if (paths.length) {
    return [selectorOrFeature, ...paths];
  }
  return selectorOrFeature.split('.');
}
function compliantPropGetter(paths) {
  const copyOfPaths = [...paths];
  return obj => copyOfPaths.reduce((acc, part) => acc && acc[part], obj);
}
const META_KEY = 'NGXS_META';
function createSelectorFactory(paths) {
  return selectorOrFeature => {
    if (typeof selectorOrFeature === 'string') {
      const propsArray = getPropsArray(selectorOrFeature, paths);
      return compliantPropGetter(propsArray);
    } else if (selectorOrFeature[META_KEY] && selectorOrFeature[META_KEY].path) {
      return compliantPropGetter(selectorOrFeature[META_KEY].path.split('.'));
    }
    return selectorOrFeature;
  };
}
function getSelectorFromInstance(instance, selectorFnName, createSelector, selectorOrFeature) {
  return instance[selectorFnName] || (instance[selectorFnName] = createSelector(selectorOrFeature));
}
function defineSelectSnapshotProperties(selectorOrFeature, paths, target, name, store) {
  const selectorFnName = `__${name.toString()}__selector`;
  const createSelector = createSelectorFactory(paths);
  Object.defineProperties(target, {
    [selectorFnName]: {
      writable: true,
      enumerable: false,
      configurable: true
    },
    [name]: {
      get() {
        const selector = getSelectorFromInstance(this, selectorFnName, createSelector, selectorOrFeature);
        // Don't use the `directiveInject` here as it works ONLY
        // during view creation.
        store = store || getStore();
        return store.selectSnapshot(selector);
      },
      enumerable: true,
      configurable: true
    }
  });
  return {
    selectorFnName,
    createSelector,
    selectorOrFeature: selectorOrFeature || removeDollarAtTheEnd(name)
  };
}
function SelectSnapshot(selectorOrFeature, ...paths) {
  return (type, name) => {
    defineSelectSnapshotProperties(selectorOrFeature, paths, type, name);
  };
}
const targetToScheduledMicrotaskMap = new Map();
const targetToCreateSelectorParamsMap = new Map();
const SUBSCRIPTIONS = Symbol('SUBSCRIPTIONS');
function ViewSelectSnapshot(selectorOrFeature, ...paths) {
  return (target, name) => {
    const createSelectorParams = targetToCreateSelectorParamsMap.get(target) || [];
    createSelectorParams.push({
      name,
      paths,
      selectorOrFeature
    });
    if (!targetToCreateSelectorParamsMap.has(target)) {
      targetToCreateSelectorParamsMap.set(target, createSelectorParams);
      // Since this is a property decorator we're not able to get the component definition and factory
      // synchronously in the current message loop tick. Note that this `Promise` will be resolved
      // before the `APP_INITIALIZER` is resolved.
      targetToScheduledMicrotaskMap.set(target, Promise.resolve().then(() => decorateTarget(target)));
    }
  };
}
function decorateTarget(target) {
  const createSelectorParams = targetToCreateSelectorParamsMap.get(target);
  const def = getDef(target.constructor);
  const factory = getFactory(target.constructor);
  // `factory` is a readonly property, but still overridable.
  def.factory = () => {
    const instance = factory();
    const store = ɵɵdirectiveInject(Store);
    const ref = ɵɵdirectiveInject(ChangeDetectorRef);
    for (const {
      name,
      paths,
      selectorOrFeature
    } of createSelectorParams) {
      const properties = defineSelectSnapshotProperties(selectorOrFeature, paths, target, name, store);
      const selector = getSelectorFromInstance(instance, properties.selectorFnName, properties.createSelector, properties.selectorOrFeature);
      const subscription = store.select(selector).subscribe(() => ref.markForCheck());
      const subscriptions = getSubscriptionsOnTheInstance(instance);
      subscriptions.push(subscription);
    }
    return instance;
  };
  overrideNgOnDestroy(target);
  targetToScheduledMicrotaskMap.delete(target);
  targetToCreateSelectorParamsMap.delete(target);
}
function overrideNgOnDestroy(target) {
  // Angular 10.0.5+ doesn't store `ngOnDestroy` hook on the component definition anymore and
  // allows to override lifecycle hooks through prototypes.
  // Previously, it was stored on the `type[NG_CMP_DEF].onDestroy` property, unfortunately,
  // it's a breaking change that'll require to use Angular 10.0.5+.
  const ngOnDestroy = target.constructor.prototype.ngOnDestroy;
  // Since Angular 10.0.5+ it's possible to override the `ngOnDestroy` directly on the prototype.
  target.constructor.prototype.ngOnDestroy = function () {
    // Invoke the original `ngOnDestroy`.
    ngOnDestroy?.call(this);
    const subscriptions = this[SUBSCRIPTIONS];
    if (Array.isArray(subscriptions)) {
      while (subscriptions.length) {
        const subscription = subscriptions.pop();
        subscription.unsubscribe();
      }
    }
  };
}
function getFactory(type) {
  return type.ɵfac;
}
function getDef(type) {
  // Gotta cast since it's `unknown`.
  const ɵcmp = type.ɵcmp;
  const ɵdir = type.ɵdir;
  return ɵcmp || ɵdir;
}
function getSubscriptionsOnTheInstance(instance) {
  return instance[SUBSCRIPTIONS] || (instance[SUBSCRIPTIONS] = []);
}
class NgxsSelectSnapshotModule {
  constructor(ngModuleRef) {
    setInjector(ngModuleRef.injector);
    ngModuleRef.onDestroy(clearInjector);
  }
  static forRoot() {
    return {
      ngModule: NgxsSelectSnapshotModule
    };
  }
}
NgxsSelectSnapshotModule.ɵfac = function NgxsSelectSnapshotModule_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || NgxsSelectSnapshotModule)(i0.ɵɵinject(i0.NgModuleRef));
};
NgxsSelectSnapshotModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: NgxsSelectSnapshotModule
});
NgxsSelectSnapshotModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgxsSelectSnapshotModule, [{
    type: NgModule
  }], function () {
    return [{
      type: i0.NgModuleRef
    }];
  }, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { NgxsSelectSnapshotModule, SelectSnapshot, ViewSelectSnapshot };
