import { AbstractControl, ValidatorFn, Validators } from '@angular/forms';
import { CommonTranslationKey } from '@hesti/models/translation-key.model';
import { ValidationError } from '../../models/validation-error.model';

export class RequiredValidator {
  public static required =
    (messageKey: CommonTranslationKey | null = 'api.validationError.required'): ValidatorFn =>
    (control: AbstractControl): ValidationError | null => {
      const validationError = new ValidationError(messageKey);
      return Validators.required(control) ? validationError : null;
    };
}
