import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApiConst } from '@hesti/constants/api.const';
import { RegistrationInfoDtoModel } from '@hesti/models/identity/registration-info/registration-info.dto-model';
import { JwtInfoDtoModel } from '@hesti/models/jwt-info/jwt-info.dto-model';
import { SignInInfoDtoModel } from '@hesti/models/identity/sign-in-info/sign-in-info.dto-model';
import { ResetPasswordInfoDtoModel } from '@hesti/models/identity/reset-password-info/reset-password-info.dto-model';
import { AdminLanguage } from '@hesti/constants/enums/language/admin-language.enum';
import { UpdatePasswordInfoDtoModel } from '@hesti/models/identity/update-password-info/update-password-info.dto-model';
import { UserInfoDtoModel } from '@hesti/models/identity/user-info/user-info.dto-model';
import { CompanyMemberPreviewDtoModel } from '@hesti/models/identity/company-member-preview/company-member-preview.dto-model';
import { RegistrationInvitationInfoDtoModel } from '@hesti/models/identity/registration-invitation-info/registration-invitation-info.dto-model';
import { ChangeEmailInfoDtoModel } from '@hesti/models/identity/change-email-info/change-email-info.dto-model';
import { UserRoleDtoModel } from '@hesti/models/identity/user-role/user-role.dto-model';
import { UserRolePermissionsDtoModel } from '@hesti/models/identity/user-role-permissions/user-role-permissions.dto-model';
import { Store } from '@ngxs/store';
import { LanguageState } from '@hesti/store/language/language.state';
import { CompanyMemberShortPreviewDtoModel } from '@hesti/models/identity/company-members-short-priview/company-member-short-preview.dto-model';
import { Theme } from '@hesti/models/theme/theme.model';

@Injectable({ providedIn: 'root' })
export class IdentityApiService {
  public constructor(
    private readonly httpClient: HttpClient,
    private readonly store: Store,
  ) {}

  public register(registrationInfoDto: RegistrationInfoDtoModel): Observable<void> {
    const language = this.store.selectSnapshot(LanguageState.language);
    return this.httpClient.post<void>(ApiConst.Identity.Register, { ...registrationInfoDto, language });
  }

  public signIn(signInInfoDto: SignInInfoDtoModel): Observable<JwtInfoDtoModel> {
    return this.httpClient.post<JwtInfoDtoModel>(ApiConst.Identity.SignIn, signInInfoDto);
  }

  public refreshToken(refreshToken: string): Observable<JwtInfoDtoModel> {
    return this.httpClient.post<JwtInfoDtoModel>(ApiConst.Identity.RefreshToken, { refreshToken });
  }

  public signOut(): Observable<void> {
    return this.httpClient.post<void>(ApiConst.Identity.SignOut, {}, { withCredentials: true });
  }

  public checkEmailUniqueness = (email: string | undefined = ''): Observable<boolean> =>
    this.httpClient.get<boolean>(ApiConst.Identity.CheckEmailUniqueness(email));

  public confirmEmail(token: string): Observable<void> {
    return this.httpClient.post<void>(ApiConst.Identity.ConfirmEmail(token), {});
  }

  public resetPassword(resetPasswordInfoDto: ResetPasswordInfoDtoModel): Observable<void> {
    return this.httpClient.post<void>(ApiConst.Identity.ResetPassword, resetPasswordInfoDto);
  }

  public signInWithGoogle(credential: string): Observable<JwtInfoDtoModel> {
    const language = this.store.selectSnapshot(LanguageState.language);
    return this.httpClient.post<JwtInfoDtoModel>(ApiConst.Identity.SignInWithGoogle, { language, credential });
  }

  public getUserInterfaceLanguage(): Observable<AdminLanguage | undefined> {
    return this.httpClient.get<AdminLanguage>(ApiConst.Identity.GetUserInterfaceLanguage);
  }

  public setUserInterfaceLanguage(language: AdminLanguage): Observable<void> {
    return this.httpClient.post<void>(ApiConst.Identity.SetUserInterfaceLanguage, { language });
  }

  public checkForPassword(): Observable<boolean> {
    return this.httpClient.get<boolean>(ApiConst.Identity.CheckForPassword);
  }

  public updatePassword(updatePasswordInfoDto: UpdatePasswordInfoDtoModel): Observable<void> {
    return this.httpClient.post<void>(ApiConst.Identity.UpdatePassword, updatePasswordInfoDto);
  }

  public getUserInfo(userId: string): Observable<UserInfoDtoModel> {
    return this.httpClient.get<UserInfoDtoModel>(ApiConst.Identity.GetUserInfo(userId));
  }

  public updateMyUserInfo(userInfoDto: UserInfoDtoModel): Observable<void> {
    return this.httpClient.put<void>(ApiConst.Identity.UpdateMyUserInfo, userInfoDto);
  }

  public updateUserInfo(userInfoDto: UserInfoDtoModel): Observable<void> {
    return this.httpClient.put<void>(ApiConst.Identity.UpdateUserInfo(userInfoDto.id), userInfoDto);
  }

  public getCompanyMembers(): Observable<CompanyMemberPreviewDtoModel[]> {
    return this.httpClient.get<CompanyMemberPreviewDtoModel[]>(ApiConst.Identity.GetCompanyMembers);
  }

  public getCompanyMemberPreviews(id: string): Observable<CompanyMemberShortPreviewDtoModel[]> {
    return this.httpClient.get<CompanyMemberShortPreviewDtoModel[]>(ApiConst.Identity.GetCompanyMemberPreviews(id));
  }

  public registerUsers(emails: string[]): Observable<void> {
    const language = this.store.selectSnapshot(LanguageState.language);
    return this.httpClient.post<void>(ApiConst.Identity.RegisterUsers, { language, emails });
  }

  public registerByInvitation(registrationInvitationInfoDto: RegistrationInvitationInfoDtoModel): Observable<void> {
    return this.httpClient.post<void>(ApiConst.Identity.RegisterByInvitation, registrationInvitationInfoDto);
  }

  public getChangeEmailRequestLockoutEnd(id: string): Observable<string | undefined> {
    return this.httpClient.get<string | undefined>(ApiConst.Identity.GetChangeEmailRequestLockoutEnd(id));
  }

  public changeEmail(changeEmailInfoDto: ChangeEmailInfoDtoModel): Observable<void> {
    return this.httpClient.post<void>(ApiConst.Identity.ChangeEmail(changeEmailInfoDto.id), changeEmailInfoDto);
  }

  public getUserRole(id: string): Observable<UserRoleDtoModel> {
    return this.httpClient.get<UserRoleDtoModel>(ApiConst.Identity.GetUserRole(id));
  }

  public updateUserRole(userRoleDto: UserRoleDtoModel): Observable<void> {
    return this.httpClient.put<void>(ApiConst.Identity.UpdateUserRole(userRoleDto.id), userRoleDto);
  }

  public getUserRolesPermissions(id: string): Observable<UserRolePermissionsDtoModel[]> {
    return this.httpClient.get<UserRolePermissionsDtoModel[]>(ApiConst.Identity.GetUserRolesPermissions(id));
  }

  public archiveUser(id: string): Observable<void> {
    return this.httpClient.post<void>(ApiConst.Identity.ArchiveUser(id), {});
  }

  public restoreUser(id: string): Observable<void> {
    return this.httpClient.post<void>(ApiConst.Identity.RestoreUser(id), {});
  }

  public deleteInvitedUser(id: string): Observable<void> {
    return this.httpClient.delete<void>(ApiConst.Identity.DeleteInvitedUser(id));
  }

  public deleteUser(id: string): Observable<void> {
    return this.httpClient.delete<void>(ApiConst.Identity.DeleteUser(id));
  }

  public getUserTheme(): Observable<Theme | undefined> {
    return this.httpClient.get<Theme | undefined>(ApiConst.Identity.GetTheme);
  }

  public updateUserTheme(theme: Theme | undefined): Observable<void> {
    return this.httpClient.post<void>(ApiConst.Identity.UpdateTheme, { theme });
  }
}
