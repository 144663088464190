export class AppRoute {
  private get isParameter(): boolean {
    return !!this.path.length && this.path[0] === ':';
  }

  public get paramName(): string {
    return this.isParameter ? this.path.slice(1, this.path.length) : '';
  }

  public constructor(
    public readonly path: string,
    private parent: AppRoute | undefined = undefined,
  ) {
    if (this.path === ':') {
      throw new Error('[app-route.model.ts] Incorrect path');
    }
  }

  public getLink(...params: string[]): string {
    if (this.isParameter && !params.length) {
      throw new Error('[app-route.model.ts] There is not enough parameter to call AppRoute.getLink method');
    }

    const path = this.isParameter ? params.pop() : this.path;

    if (!this.parent && params.length) {
      throw new Error('[app-route.model.ts] Too many parameters to call the AppRoute.getLink method');
    }

    return this.parent ? [this.parent.getLink(...params), path].join('/') : `/${path}`;
  }
}
