import { AppRoute } from '@hesti/models/app-route.model';

export namespace AppRoutes {
  export class Portal {
    public static readonly Properties = new AppRoute('properties');
    public static readonly Property = new AppRoute(':propertyId', Portal.Properties);
  }

  export class Hesti {
    public static readonly Main = new AppRoute('');

    public static readonly SignIn = new AppRoute('sign-in');
    public static readonly Registration = new AppRoute('registration');
    public static readonly PasswordResetRequest = new AppRoute('password-reset');
    public static readonly PasswordReset = new AppRoute(':token', Hesti.PasswordResetRequest);
    public static readonly ConfirmEmail = new AppRoute('confirm-email');
    public static readonly ConfirmEmailToken = new AppRoute(':token', Hesti.ConfirmEmail);
    public static readonly RegistrationInvitation = new AppRoute('registration-invitation');
    public static readonly RegistrationInvitationToken = new AppRoute(':token', Hesti.RegistrationInvitation);

    public static readonly Admin = new AppRoute('admin');

    public static readonly MyPortal = new AppRoute('portal', Hesti.Admin);

    public static readonly PropertyGeneralPath = 'general';
    public static readonly PropertyTranslationsPath = 'translations';

    public static readonly Properties = new AppRoute('properties', Hesti.Admin);
    public static readonly PropertyEntity = new AppRoute(':propertyId', Hesti.Properties);
    public static readonly PropertyNewEntity = new AppRoute('new', Hesti.Properties);

    public static readonly Company = new AppRoute('company', Hesti.Admin);
    public static readonly EditCompany = new AppRoute('edit', Hesti.Company);

    public static readonly RestoreCompany = new AppRoute('restore-company');

    public static readonly Subscription = new AppRoute('subscription', Hesti.Admin);

    public static readonly EditAccountPath = 'edit';
    public static readonly AccountPersonalInfoPath = 'personal-info';
    public static readonly AccountRolePath = 'role';
    public static readonly AccountSecurityPath = 'security';
    public static readonly AccountSettingsPath = 'settings';

    public static readonly MyAccount = new AppRoute('my-account', Hesti.Admin);
    public static readonly MyAccountProperty = new AppRoute(':propertyId', Hesti.MyAccount);
    public static readonly BaseAccount = new AppRoute('account', Hesti.Admin);
    public static readonly Account = new AppRoute(':accountId', Hesti.BaseAccount);
    public static readonly AccountProperty = new AppRoute(':propertyId', Hesti.Account);
  }
}
