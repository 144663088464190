import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ErrorCode } from '@hesti/constants/error-code.const';
import { ApiErrorModel } from '@hesti/models/api-error/api-error.model';
import { NgSwitch, NgSwitchCase } from '@angular/common';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { InternalServerErrorComponent } from './internal-server-error/internal-server-error.component';
import { SomethingWentWrongComponent } from './something-went-wrong/something-went-wrong.component';

@Component({
  standalone: true,
  selector: 'hp-api-error',
  templateUrl: './api-error.component.html',
  styleUrls: ['./api-error.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgSwitch, NgSwitchCase, PageNotFoundComponent, InternalServerErrorComponent, SomethingWentWrongComponent],
})
export class ApiErrorComponent {
  @Input() public apiError: ApiErrorModel;

  public readonly ApiErrorCode = ErrorCode;
}
