import { Action, Selector, State, StateContext } from '@ngxs/store';
import { UserStateModel } from '@hesti/store/user/user.state.model';
import { StoreConst } from '@hesti/constants/store.const';
import { Injectable, NgZone } from '@angular/core';
import { JwtInfoModel } from '@hesti/models/jwt-info/jwt-info.model';
import { IdentityApiService } from '@hesti/services/api/identity-api.service';
import { UserStateActions } from '@hesti/store/user/user.state.actions';
import { JwtInfoDtoModel } from '@hesti/models/jwt-info/jwt-info.dto-model';
import { ActivatedRoute, Router } from '@angular/router';
import { AppRoutes } from '@hesti/constants/app-routes.const';
import { HttpErrorResponse } from '@angular/common/http';
import { ApiErrorModel } from '@hesti/models/api-error/api-error.model';
import { catchError, combineLatest, finalize, map, Observable, of, switchMap } from 'rxjs';
import { SpinnerService } from '@hesti/services/spinner/spinner.service';
import { LanguageStateActions } from '@hesti/store/language/language.state.actions';
import { JwtUserInfoModel } from '@hesti/models/jwt-user-info/jwt-user-info.model';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { LocalStorageService } from '@hesti/services/local-storage/local-storage.service';
import { Moment } from 'moment';
import { CompanyInfoStateActions } from '@hesti/store/company-info/company-info.state.actions';
import { ThemeStateActions } from '@hesti/store/theme/theme.state.actions';

@UntilDestroy()
@State<UserStateModel>({
  name: StoreConst.UserState,
  defaults: {
    requestedEmail: undefined,
    jwtInfo: undefined,
    apiError: undefined,
  },
})
@Injectable()
export class UserState {
  public constructor(
    private readonly identityApiService: IdentityApiService,
    private readonly ngZone: NgZone,
    private readonly activatedRoute: ActivatedRoute,
    private readonly router: Router,
    private readonly spinnerService: SpinnerService,
    private readonly localStorageService: LocalStorageService,
  ) {}

  @Selector()
  public static requestedEmail({ requestedEmail }: UserStateModel): string | undefined {
    return requestedEmail;
  }

  @Selector()
  public static jwtInfo({ jwtInfo }: UserStateModel): JwtInfoModel | undefined {
    return jwtInfo;
  }

  @Selector()
  public static accessToken({ jwtInfo }: UserStateModel): string | undefined {
    return jwtInfo?.accessToken;
  }

  @Selector()
  public static isAuthenticated({ jwtInfo }: UserStateModel): boolean {
    return !!jwtInfo?.accessToken;
  }

  @Selector()
  public static isCompanyArchived({ jwtInfo }: UserStateModel): boolean | undefined {
    return jwtInfo?.userInfo.IsCompanyArchived;
  }

  @Selector()
  public static CompanyArchivePeriodEnd({ jwtInfo }: UserStateModel): Moment | undefined {
    return jwtInfo?.userInfo.CompanyArchivePeriodEnd;
  }

  @Selector()
  public static userInfo({ jwtInfo }: UserStateModel): JwtUserInfoModel | undefined {
    return jwtInfo?.userInfo;
  }

  @Selector()
  public static apiError({ apiError }: UserStateModel): ApiErrorModel | undefined {
    return apiError;
  }

  @Action(UserStateActions.SignIn)
  public signIn(context: StateContext<UserStateModel>, { signInInfo }: UserStateActions.SignIn): void {
    context.patchState({ requestedEmail: signInInfo.email });

    this.identityApiService
      .signIn(signInInfo)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (jwtInfoDtoModel) => this.handleSuccessfulSignIn(context, jwtInfoDtoModel),
        error: (httpErrorResponse: HttpErrorResponse) => this.setApiErrorState(context, httpErrorResponse),
      });
  }

  @Action(UserStateActions.ResetApiError)
  public resetApiError(context: StateContext<UserStateModel>): void {
    context.patchState({ apiError: undefined });
  }

  @Action(UserStateActions.SignInWithGoogle)
  public signInWithGoogle(context: StateContext<UserStateModel>, { credential }: UserStateActions.SignInWithGoogle): void {
    this.identityApiService
      .signInWithGoogle(credential)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (jwtInfoDtoModel) => this.handleSuccessfulSignIn(context, jwtInfoDtoModel),
        error: (httpErrorResponse: HttpErrorResponse) => this.setApiErrorState(context, httpErrorResponse),
      });
  }

  @Action(UserStateActions.RefreshToken)
  public refreshToken(context: StateContext<UserStateModel>): Observable<void> {
    this.spinnerService.show();
    const refreshToken = this.localStorageService.get<string>(StoreConst.UserState) || '';
    return this.identityApiService.refreshToken(refreshToken).pipe(
      catchError((httpErrorResponse: HttpErrorResponse) => {
        this.setApiErrorState(context, httpErrorResponse);
        return of();
      }),
      switchMap((jwtInfoDtoModel) => (jwtInfoDtoModel ? this.setUserInfoAndPreferences(context, jwtInfoDtoModel) : of())),
      finalize(() => this.spinnerService.hide()),
    );
  }

  @Action(UserStateActions.SignOut)
  public signOut({ patchState, dispatch }: StateContext<UserStateModel>): void {
    this.identityApiService.signOut().pipe(untilDestroyed(this)).subscribe();
    patchState({ jwtInfo: undefined });
    this.ngZone.run(() => this.router.navigate([AppRoutes.Hesti.SignIn.getLink()]));
    dispatch(new LanguageStateActions.RestoreLanguage());
  }

  @Action(UserStateActions.SetJwtInfo)
  public setJwtInfo({ patchState }: StateContext<UserStateModel>, { jwtInfo }: UserStateActions.SetJwtInfo): void {
    patchState({ jwtInfo });
  }

  private handleSuccessfulSignIn(context: StateContext<UserStateModel>, jwtInfoDto: JwtInfoDtoModel): void {
    this.setUserInfoAndPreferences(context, jwtInfoDto)
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        const nextUrl = this.activatedRoute.snapshot.queryParams['returnUrl'] || AppRoutes.Hesti.Admin.getLink();
        this.ngZone.run(() => this.router.navigateByUrl(nextUrl));
      });
  }

  private setUserInfoAndPreferences({ patchState, dispatch }: StateContext<UserStateModel>, jwtInfoDto: JwtInfoDtoModel): Observable<void> {
    patchState({ jwtInfo: new JwtInfoModel(jwtInfoDto) });
    this.localStorageService.set(StoreConst.UserState, jwtInfoDto.refreshToken, true);

    return combineLatest([
      dispatch(new LanguageStateActions.LoadAuthenticatedUserLanguage()),
      dispatch(new ThemeStateActions.LoadUserTheme()),
      dispatch(new CompanyInfoStateActions.LoadSubdomain()),
      dispatch(new CompanyInfoStateActions.LoadLanguages()),
    ]).pipe(map(() => undefined));
  }

  private setApiErrorState(context: StateContext<UserStateModel>, httpErrorResponse: HttpErrorResponse): void {
    const apiError = httpErrorResponse.error?.errors?.[0];
    if (apiError) {
      context.patchState({ jwtInfo: undefined, apiError: new ApiErrorModel(apiError) });
    }
  }
}
