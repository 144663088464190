import { Directive, ElementRef, OnInit } from '@angular/core';

@Directive({
  standalone: true,
  selector: 'img[haImageSkeleton]',
})
export class ImageSkeletonDirective implements OnInit {
  private readonly imageSkeletonClass = 'ha-image-skeleton';

  public constructor(private readonly elementRef: ElementRef) {}

  public ngOnInit(): void {
    const element = this.elementRef.nativeElement as HTMLImageElement;
    const skeletonImage = document.createElement('div');
    skeletonImage.classList.add(this.imageSkeletonClass);
    const computedStyle = getComputedStyle(element.parentElement!);
    if (computedStyle.position !== 'absolute') {
      element.parentElement!.style.position = 'relative';
    }
    element.parentElement!.appendChild(skeletonImage);
    element.style.opacity = '0.17';
    element.onload = (): void => {
      skeletonImage.remove();
      element.style.opacity = '1';
    };
    element.onerror = (): void => {
      skeletonImage.remove();
      element.style.opacity = '1';
    };
  }
}
