import { CompanyLogoDtoModel } from '@hesti/models/company/company-logo/company-logo.dto-model';
import { ThemeMode } from '@hesti/models/theme/theme-mode.model';

export namespace CompanyInfoStateActions {
  const prefix = '[CompanyInfo]';

  export class LoadCompanyInfo {
    public static readonly type = `${prefix} Load Company Info`;
  }

  export class UpdateCompanyLogos {
    public static readonly type = `${prefix} Update Company Logos`;

    public constructor(public readonly logos: CompanyLogoDtoModel[]) {}
  }

  export class UpdateThemeMode {
    public static readonly type = `${prefix} Update Theme Mode`;

    public constructor(public readonly themeMode: ThemeMode) {}
  }
}
