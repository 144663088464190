import { clone } from 'lodash-es';

export class SpinnerStack {
  public get shouldShowSpinner(): boolean {
    return !this.previousStack.length && !!this.stack.length;
  }

  public get shouldHideSpinner(): boolean {
    return !!this.previousStack.length && !this.stack.length;
  }

  public constructor(
    public readonly name: string,
    public stack: boolean[] = [],
    public previousStack: boolean[] = [],
  ) {}

  public push(): void {
    this.previousStack = clone(this.stack);
    this.stack.push(true);
  }

  public pop(): void {
    this.previousStack = clone(this.stack);
    this.stack.pop();
  }
}
